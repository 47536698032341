import * as THREE from "three";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import u from "./utils";

class Fest23Graphic {
	constructor(container, onGfxLoaded) {
		this.container = container;
		this.onGfxLoaded = onGfxLoaded;

		this.width = window.innerWidth;
		this.height = this.container.offsetHeight;
		const aspect = this.width / this.height;

		this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		this.renderer.setSize(this.width, this.height);
		container.appendChild(this.renderer.domElement);

		this.scene = new THREE.Scene();
		this.loader = new THREE.TextureLoader();

		this.camera = new THREE.PerspectiveCamera(39, aspect, 1, 10000);

		this.displacementMapCanvas = null;
		this.displacementMapCtx = null;
		this.gfxMesh = null;

		this.fps = 30;

		this.lastGlitch = null;
		this.glitchDelay = 200;

		this.lastGrowth = null;
		this.displacementGrowthDelay = 100;

		this.glitchYOffset = 0.8;

		this.startDisplacement = 40;
		this.maxDisplacement = 75;

		this.startingCamY = -800;
		this.startingCamZ = 470;

		this.endingCamY = -650;
		this.endingCamZ = 820;

		this.prefersReducedMotion = window.matchMedia(
			"(prefers-reduced-motion: reduce)"
		).matches;

		this.setup();
	}

	async setup() {
		await this.create3D();
		this.updateCamera();
		this.setupEvents();
		this.animate();
		window.setTimeout(this.onGfxLoaded, 50);
	}

	updateCamera() {
		this.camera.position.y = this.startingCamY;
		this.camera.position.z = this.startingCamZ;
		this.camera.position.x = 0;
		this.camera.lookAt(new THREE.Vector3(0, 0, 0));
	}

	async create3D() {
		const imgUrl = this.container.dataset[`fest23GfxAsset`];
		const displacementUrl = this.container.dataset[
			`fest23GfxAssetDisplacement`
		];

		// The gfx combines two assets:
		// - A visual texture which holds the photographic imagery
		// - A displacement map which holds the black and white image which augments the mesh into
		//   a 3D shape which matches the photographic imagery
		const [visualTexture, displacementMapCanvas] = await Promise.all([
			this.loader.loadAsync(imgUrl),
			u.imageCanvas(displacementUrl)
		]);

		this.displacementMapCanvas = displacementMapCanvas;
		this.displacementMapCtx = this.displacementMapCanvas.getContext("2d");

		const displacementMapTexture = new THREE.CanvasTexture(
			this.displacementMapCanvas
		);

		const displacementMapMaterial = new THREE.MeshPhongMaterial({
			displacementMap: displacementMapTexture,
			map: visualTexture,
			shininess: 0,
			displacementScale: this.startDisplacement,
			color: new THREE.Color(0xffffff)
		});

		// meshSegments controls how many vertices are in the mesh. The more vertices, the more
		// detailed the displacement map appears when rendered. However, more vertices also means
		// more work for the GPU, so we need to find a balance between detail and performance.
		const meshSegments = 1000;
		const gfxGeo = new THREE.PlaneGeometry(
			displacementMapCanvas.width,
			displacementMapCanvas.height,
			meshSegments,
			meshSegments
		);
		this.gfxMesh = new THREE.Mesh(gfxGeo, displacementMapMaterial);

		this.gfxMesh.position.z = 50;
		this.gfxMesh.position.y = -150;

		this.scene.add(this.gfxMesh);

		const light = new THREE.DirectionalLight(0xfffefa, 0.95);
		light.position.set(0, 0.5, 1).normalize();
		this.scene.add(light);
	}

	onResize() {
		this.width = window.innerWidth;
		this.height = this.container.offsetHeight;
		const aspect = this.width / this.height;

		this.renderer.setSize(this.width, this.height);
		this.camera.aspect = aspect;
		this.camera.updateProjectionMatrix();

		this.updateCamera();
	}

	setupEvents() {
		gsap.to(this.camera.position, {
			y: this.endingCamY,
			z: this.endingCamZ,
			scrollTrigger: {
				trigger: this.renderer.domElement,
				start: "top bottom",
				scrub: 1
			}
		});

		let xTo = gsap.quickTo(this.camera.position, "x", {
			duration: 0.6,
			ease: "power3"
		});

		window.addEventListener("mousemove", e => {
			if (e) {
				const pct = e.clientX / window.innerWidth - 0.5;
				xTo(pct * -5);
			}
		});

		window.addEventListener(
			"resize",
			u.debounce(this.onResize.bind(this), 200)
		);
	}

	animate() {
		// GSAP deals with different refresh rates across different displays and uses RAF under
		// the hood. Given we already load it, we'll use it.
		gsap.set(this.runFrame.bind(this), {
			delay: 1 / this.fps,
			onRepeat: this.runFrame.bind(this),
			repeat: -1,
			repeatDelay: 1 / this.fps
		});
	}

	runFrame() {
		const now = performance.now();

		const timeSinceLastGrowth = now - this.lastGrowth;
		if (
			!this.lastGrowth ||
			(timeSinceLastGrowth >= this.displacementGrowthDelay &&
				this.gfxMesh.material.displacementScale < this.maxDisplacement)
		) {
			this.lastGrowth = now;
			this.gfxMesh.material.displacementScale += 0.01;
		}

		// If enough time has lapsed since the last glitch, then add some glitch.
		// With some randomness applied to the timing to make it less consistent.
		const timeSinceLastGlitch = now - this.lastGlitch;
		if (
			!this.lastGlitch ||
			(timeSinceLastGlitch >= this.glitchDelay && Math.random() > 0.3)
		) {
			this.lastGlitch = now;

			// Find a random horizontal section in the displacement map canvas and copy it to
			// a new location. This creates the effect of the map slowly corrupting over time.
			const r = u.getRandomInRange;
			const canW = this.displacementMapCanvas.width;
			const canH = this.displacementMapCanvas.height;
			const wSegs = 5; // How many horizontal sections to divide the canvas into
			const wUnit = canW / wSegs;

			const xSz = r(1, wSegs) * wUnit;
			const ySz = r(10, 100);

			const x = r(0, wSegs - 1) * wUnit;
			const dx = r(0, wSegs - 1) * wUnit;

			const yMin = (canH - ySz) * this.glitchYOffset;
			const y = r(yMin, canH - ySz);
			const dy = r(yMin, canH - ySz);

			// This is a work around for Chrome on android which clears the whole canvas when you
			// draw to just a section of it using drawImage. Drawing the full image directly before
			// drawing the section seems to fix it. I think it's related to this bug:
			// https://bugs.chromium.org/p/chromium/issues/detail?id=1472700
			this.displacementMapCtx.drawImage(this.displacementMapCanvas, 0, 0);

			this.displacementMapCtx.drawImage(
				this.displacementMapCanvas,
				x,
				y,
				xSz,
				ySz,
				dx,
				dy,
				xSz,
				ySz
			);

			// glitchYOffset is increased slowly so that the corruption starts at the bottom of the
			// canvas and slowely moved upwards over time.
			this.glitchYOffset = Math.max((this.glitchYOffset -= 0.003), 0);

			// The underlying Canvas which holds the displacementMap image is being modified above,
			// which means we need to tell three to recalculate the shaders which render the texture.
			// We do this by setting needsUpdate to true. This needs to be called each render,
			// not just once on setup.
			//
			// Read more about what updated require manual intervention here:
			// https://threejs.org/docs/index.html#manual/en/introduction/How-to-update-things
			this.gfxMesh.material.displacementMap.needsUpdate = true;
		}

		// We're animating the camera on scroll, we don't want to delay the render otherwise
		// the animation will be choppy.
		this.render();
	}

	render() {
		this.camera.lookAt(new THREE.Vector3(0, 0, 0));
		this.renderer.render(this.scene, this.camera);
	}
}

export { Fest23Graphic };
