import { Loader } from "@googlemaps/js-api-loader";

import u from "./utils";

let INACTIVE_MAP_ICON = {
	path: " M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
	scale: 0.08,
	strokeWeight: 0,
	fillOpacity: 1,
	fillColor: "#ffa17b"
};

let ACTIVE_MAP_ICON = {
	path: " M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
	scale: 0.15,
	strokeWeight: 0,
	fillOpacity: 1,
	fillColor: "#ff5f1f"
};

let INACTIVE_TRAIL_MAP_ICON = {
	path: " M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
	scale: 0.08,
	strokeWeight: 0,
	fillOpacity: 1,
	fillColor: "#ffffff"
};

let ACTIVE_TRAIL_MAP_ICON = {
	path: " M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
	scale: 0.15,
	strokeWeight: 0,
	fillOpacity: 1,
	fillColor: "#ff5f1f"
};

export default function initMapOverlay() {
	if (!document.querySelector("[data-map-overlay]")) {
		return false;
	}
	return new MapOverlay();
}

class MapOverlay {
	constructor() {
		if (window.google) {
			this.init();
		} else {
			const loader = new Loader({
				apiKey: GMAPS_KEY,
				version: 3.54,
				libraries: ["geometry"]
			});

			loader.load().then(this.init.bind(this));
		}
	}

	async init() {
		this.setup();
		await this.createMap();
		await this.setupOffscreenArrows();
		this.setupEvents();
	}

	setup() {
		INACTIVE_MAP_ICON.anchor = new google.maps.Point(85, 85);
		ACTIVE_MAP_ICON.anchor = new google.maps.Point(91, 91);
		INACTIVE_TRAIL_MAP_ICON.anchor = new google.maps.Point(85, 85);
		ACTIVE_TRAIL_MAP_ICON.anchor = new google.maps.Point(91, 91);

		this.icons = {
			festival: {
				active: ACTIVE_MAP_ICON,
				inactive: INACTIVE_MAP_ICON
			},
			trail: {
				active: ACTIVE_TRAIL_MAP_ICON,
				inactive: INACTIVE_TRAIL_MAP_ICON
			}
		};

		this.$mapOverlay = document.querySelector("[data-map-overlay]");
		this.$canvas = this.$mapOverlay.querySelector("[data-interactive-map]");
		this.$canvasInner = this.$mapOverlay.querySelector(
			"[data-interactive-map-inner]"
		);
		this.$venueElements = this.$mapOverlay.querySelectorAll(
			"[data-venue-latlng]"
		);
		this.mapCenter = new google.maps.LatLng(-42.0800922, 145.5557205);
		this.mapZoom = window.screen.width <= 768 ? 15 : 16;
		this.mapZoomCloseUp = 17;
		this.markers = [];
		this.offscreenMarkerArrows = [];
		this.$closeVenueDetails = this.$mapOverlay.querySelector(
			"[data-venue-close]"
		);
		this.$details = this.$mapOverlay.querySelector("[data-map-details]");
		this.$overview = this.$mapOverlay.querySelector("[data-map-overview]");
	}

	async createMap() {
		const { Map } = await google.maps.importLibrary("maps");

		this.venues = Array.prototype.map.call(this.$venueElements, $venue => {
			return {
				latlng: $venue.dataset.venueLatlng.replace(" ", "").split(","),
				type: $venue.dataset.venueType,
				title: $venue.innerText.trim(),
				offscreen: $venue.dataset.offScreen !== undefined ? true : false
			};
		});

		this.map = new Map(this.$canvas, {
			mapId: "acaa28de2de49b38",
			center: this.mapCenter,
			zoom: this.mapZoom,
			maxZoom: 18,
			minZoom: 13,
			disableDefaultUI: true,
			gestureHandling: "greedy",
			zoomControl: false,
			keyboardShortcuts: false
		});

		// markers should only need to be generated once (on map initialisation)
		this.venues.forEach((venue, i) => {
			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(venue.latlng[0], venue.latlng[1]),
				map: this.map,
				icon: this.icons[venue.type].inactive
			});
			marker.locationId = venue.latlng;
			marker.venueData = venue;

			this.markers.push(marker);
		});

		const urlParams = new URLSearchParams(window.location.search);
		const selectedVenue = urlParams.get("venue");

		if (selectedVenue) {
			this.selectVenue(selectedVenue);
		}
	}

	async setupOffscreenArrows() {
		const geometry = await google.maps.importLibrary("geometry");
		const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

		const buildMarkerHtml = (title, heading, labelLocation) => {
			const outer = document.createElement("div");
			outer.classList.add("offscreet-marker");
			const label = document.createElement("span");
			label.classList.add("offscreet-marker--label");
			label.classList.add(`offscreet-marker--label__${labelLocation}`);
			label.innerHTML = title;

			const arrowUpSvg = `
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path
										d="M 12 2 L 4 22 L 20 22 L 12 2 Z"
										fill="#ff5f1f"
										stroke-width="0"></path>
								</svg>`;

			outer.innerHTML = arrowUpSvg;

			const arrow = outer.querySelector("svg");
			arrow.classList.add("offscreet-marker--arrow");
			arrow.style.transform = `rotate(${heading}deg)`;

			outer.appendChild(label);

			return outer;
		};

		const buildOffscreenMarkers = () => {
			const edgeNE = this.map.getBounds().getNorthEast();
			const edgeSW = this.map.getBounds().getSouthWest();

			const zoomMap = {
				13: 0.003,
				14: 0.002,
				15: 0.001,
				16: 0.0005,
				17: 0.0002,
				18: 0.0001
			};

			this.venues
				.filter(venue => venue.offscreen)
				.forEach(venue => {
					const venuePos = new google.maps.LatLng(
						venue.latlng[0],
						venue.latlng[1]
					);

					// Only show offscreen markers if the venue is offscreen
					if (this.map.getBounds().contains(venuePos)) {
						return;
					}

					// Calc how much to rotate arrow so it points towards the offscreen marker
					const heading = geometry.spherical.computeHeading(
						this.map.getCenter(),
						venuePos
					);

					// Calc which edges to use to place the arrows towards the offscreen markers.
					const latSource = Math.abs(heading) <= 90 ? edgeNE : edgeSW;
					const latMod = Math.abs(heading) <= 90 ? -1 : 1;
					const lngSource = heading < 0 ? edgeSW : edgeNE;
					const lngMod = heading < 0 ? +1 : -1;

					// Calculate how big our mod factor should be based on zoom level.
					const zoom = this.map.getZoom();
					const modAmount = zoomMap[parseInt(zoom)];

					// Modifiy the edge position so the arrow is not right on the edge of the map
					const edgePosition = new google.maps.LatLng(
						latSource.lat() + latMod * modAmount,
						lngSource.lng() + lngMod * modAmount
					);

					const labelLocation = heading < 0 ? "right" : "left";

					// Now that we have used the heading to determine which edges to use, we can
					// update the heading to more accurately point towards the venue. This becomes
					// necesary as the user gets closer to the venue whilst panning.
					const accurateHeading = geometry.spherical.computeHeading(
						edgePosition,
						venuePos
					);

					const marker = new AdvancedMarkerElement({
						position: edgePosition,
						map: this.map,
						content: buildMarkerHtml(
							venue.title,
							accurateHeading,
							labelLocation
						)
					});

					this.offscreenMarkerArrows.push(marker);

					google.maps.event.addListener(marker, "click", _ => {
						const selector = Array.from(venue.latlng).join(", ");
						this.selectVenue(selector);
					});
				});
		};

		// Use idle event to do work that requires Map() to be fully initialised (eg getBounds)
		google.maps.event.addListenerOnce(this.map, "idle", buildOffscreenMarkers);
		this.map.addListener(
			"bounds_changed",
			u.debounce(() => {
				this.offscreenMarkerArrows.forEach(marker => {
					marker.setMap(null);
				});
				buildOffscreenMarkers();
			}, 200)
		);
	}

	setupEvents() {
		this.markers.forEach(m => {
			google.maps.event.addListener(m, "click", _ => {
				const selector = Array.from(m.locationId).join(", ");
				this.selectVenue(selector);
			});
		});

		this.$venueElements.forEach($venue => {
			$venue.addEventListener("click", e => {
				this.selectVenue($venue.dataset.venueLatlng);
			});
		});

		this.$closeVenueDetails.addEventListener("click", e => {
			//clear venue detail list (so we can repopulate when selecting a new venue)
			this.clearVenueEvents();
			this.backToVenueList();
			e.preventDefault();
		});
	}

	selectVenue(locationId) {
		this.clearVenueEvents();

		this.markers.forEach(m => {
			m.setIcon(this.icons[m.venueData.type].inactive);
			if (Array.from(m.locationId).join(", ") === locationId) {
				m.setIcon(this.icons[m.venueData.type].active);
			}
		});

		this.venues.forEach(venue => {
			const selector = venue.latlng.join(", ");
			const $venueListItem = this.$mapOverlay.querySelector(
				"[data-venue-latlng='" + selector + "']"
			);
			const $venueDetails = this.$mapOverlay.querySelector(
				"[data-venue-event='" + selector + "']"
			);

			if ($venueListItem.dataset.venueLatlng == locationId) {
				$venueDetails.classList.add("venue-active");
				this.$details.querySelector("[data-venue-title]").innerHTML =
					$venueListItem.innerText;

				u.gEvent("Map", "select_venue", $venueListItem.innerHTML);

				this.$overview.classList.add("event-detail-active");
				this.$details.classList.add("event-detail-active");

				this.map.panTo(
					new google.maps.LatLng(venue.latlng[0], venue.latlng[1])
				);
				this.map.setZoom(this.mapZoomCloseUp);
			}
		});
	}

	backToVenueList() {
		this.$overview.classList.remove("event-detail-active");
		this.$details.classList.remove("event-detail-active");
		this.map.setCenter(this.mapCenter);
		this.map.setZoom(this.mapZoom);
	}

	clearVenueEvents() {
		const $activeEvent = this.$mapOverlay.querySelector(".venue-active");
		this.markers.forEach(m => {
			m.setIcon(this.icons[m.venueData.type].inactive);
		});
		if ($activeEvent) {
			$activeEvent.classList.remove("venue-active");
		}
	}
}
